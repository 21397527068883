<template>
  <div class="purchaseContractManage">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :search-form-item-arr="formItemArr" :form-inline="formInline" :reset="true" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
      >
        <template #expiryContractDate="{row}">
          {{ row.expiryContractDate||'-' }}
          <!-- v-if="row.isOverdue==='1'" -->
          <div v-if="row.isOverdue==='1'" class="overdue-box">
            已逾期
          </div>
        </template>
      </Table>
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 选择账户支付 -->
    <SelectAccount ref="selectAccount" :money="paymentObj.money" @getAccount="getAccount" />
    <pdf-contract
      v-if="agreementConfig.visible"
      :visible.sync="agreementConfig.visible"
      :url="agreementConfig.data.contractFileUrl"
      :sign-or-not="agreementConfig.signOrNot"
      @confirmSign="confirmSign"
    />
    <el-dialog title="支付保证金" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <p class="aggregateAmount">
        需支付保证金总额：<span>{{ Math.abs(depositListDataObj.totalMarginAmount) }}</span>元
        <!-- 显示支付银行 -->
        <span v-if="paymentObj.settleBankName">{{ paymentObj.settleBankName }}</span>
      </p>
      <Table :item-data="depositItemData" :list-data="depositListDataObj.agentDepositDetails" />
      <div slot="footer" class="dialog-footer table-operation">
        <el-button size="small" plain class="widen-button" @click="dialogTableVisible = false">
          取消
        </el-button>
        <el-button size="small" type="primary" class="widen-button" @click="paymentMoney">
          支付
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import SelectAccount from '@/components/SelectAccount'
import PdfContract from '@/components/PdfContract'
import Pagination from '@/components/Pagination'
import { getBankName, roundUpToInteger } from '@/unit/index'
import { agentPurchasePlanDepositPayment } from '@/http/custApi/purchasePlanManage'
import { deliveryInfoCheckStartDeliveryApply, agentPurchaseContractRefusedSign, agentPurchaseContractDepositPayment, agentPurchaseContractPage, agentPurchaseContractGetEsignUrl, agentPurchaseContractToDepositList, agentPurchaseContractGetDepositPaymentUrl } from '@/http/custApi/purchaseContractManage'
export default {
  components: {
    FormSearch,
    Pagination,
    Table,
    SelectAccount,
    PdfContract
  },
  data() {
    return {
      dialogTableVisible: false,
      depositListDataObj: {
        agentDepositDetails: [],
        totalMarginAmount: 0
      },
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '', name: '采购合同管理' }],
      formItemArr: [
        { type: 'input', label: '合同编号', value: 'contractNo' },
        { type: 'input', label: '合作企业', value: 'supplierCmpName' },
        { type: 'input', label: '代采机构', value: 'fundsCmpName', width: '120' },
        { type: 'select', label: '合同状态', value: 'contractStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('custContractStatus') },
        { type: 'radio', label: '是否逾期', value: 'isOverdue', width: '120', pLabel: 'dictName', pValue: 'dictId', child: [{ dictName: '是', dictId: '1' }, { dictName: '否', dictId: '0' }] }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        fundsCmpName: '',
        contractStatus: '',
        supplierCmpName: ''
      },
      depositItemData: [
        { label: '待支付日期', prop: 'payableDate' },
        { label: '付款比例(%)', prop: 'paymentRate' },
        { label: '保证金金额(元)', prop: 'depositPayable', type: 'money' }
      ],
      itemData: [
        { label: '合同编号', prop: 'contractNo', width: 120 },
        { label: '采购计划编号', prop: 'planNo', width: 120 },
        { label: '合作企业', prop: 'supplierCmpName', width: 130 },
        { label: '代采机构', prop: 'fundsCmpName', width: 140 },
        { label: '合同总额(元)', prop: 'aggregateAmount', width: 100, type: 'money' },
        { label: '保证金(元)', prop: 'depositPayable', width: 100, type: 'money' },
        // { label: '代采费率', prop: 'agentRate', width: 80 },
        { label: '合同到期日', prop: 'expiryContractDate', width: 100 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        // { label: '付款状态', prop: 'paymentStatus', width: 120, child: this.$store.getters.getDictionaryItem('paymentStatus') },
        { label: '合同状态', prop: 'contractStatus', width: 120, child: this.$store.getters.getDictionaryItem('custContractStatus') }
      ],
      listData: [],
      total: null,
      paymentObj: {},
      operationButton: [
        { num: 5, val: 'paymentStatus', bType: 'primary', label: '支付保证金', handleEvent: this.marginPayment },
        { num: 6, val: 'paymentStatus', bType: 'primary', label: '去支付', handleEvent: this.marginPayment },
        { num: 8, val: 'contractStatus', bType: 'primary', label: '支付保证金', handleEvent: this.paymentBondTable },
        { child: [{ num: 1, val: 'contractStatus' }, { val: 'contractFileUrl' }], bType: 'primary', label: '签署协议', handleEvent: this.agreementSignTable },
        { num: 1, val: 'contractStatus', bType: 'primary', label: '拒绝签署', handleEvent: this.refusedSign },
        { num: 2, val: 'deliveryButtonShow', bType: 'primary', label: '提货申请', handleEvent: this.aeliveryApplicationTable },
        { num: 15, val: 'contractStatus', bType: 'primary', label: '逾期支付', handleEvent: this.backPayment },
        { bType: 'primary', label: '查看协议', handleEvent: this.viewAgreementTable },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      agreementConfig: {
        visible: false,
        data: {},
        signOrNot: null
      }
    }
  },
  created() {
    if (this.$route.query.contractStatus) this.formInline.contractStatus = this.$route.query.contractStatus
  },
  methods: {
    // 拒绝签署
    async refusedSign(row) {
      const confirm = await this.$confirm(`您的操作将终止此条合同签署，是否继续？`, '提示', { type: 'error' }).catch(err => err)
      if (confirm !== 'confirm') return
      agentPurchaseContractRefusedSign({
        id: row.id,
        contractStatus: row.contractStatus
      }, () => {
        this.$message.success('成功！')
        this.getdata(true)
      })
    },
    // 获取数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentPurchaseContractPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.aggregateAmount = roundUpToInteger((Number(item.totalPayment) || 0) + (Number(item.estimateCost) || 0))
        })
        this.total = res.data.total
      })
    },
    // 查看详情
    viewInfoTable({ id }) {
      this.$router.push({ path: '/purchaseContractManage/contractInfo', query: { id }})
    },
    // 逾期支付
    backPayment({ id }) {
      this.$router.push({ path: '/purchaseContractManage/contractInfo', query: { id, index: 10 }})
    },
    // 点击支付按钮，去选银行卡
    async paymentMoney() {
      // 如果有了银行卡  就不要去选了
      if (this.paymentObj.settleBankId) {
        const confirm = await this.$confirm(`确认是否支付保证金${this.paymentObj.money}元？`, '提示', { type: 'warning' }).catch(err => err)
        if (confirm !== 'confirm') return
        this.getAccount(false)
        return
      }
      this.$refs.selectAccount.dialogTableVisible = true
    },
    // 点击支付保证金弹出支付弹出窗
    marginPayment(row) {
      // 从接口上获取参数进行支付
      agentPurchaseContractToDepositList(row.id, res => {
        this.dialogTableVisible = true
        this.depositListDataObj = { ...res.data }
        const depositDetailIds = []
        this.depositListDataObj.agentDepositDetails.forEach(item => {
          depositDetailIds.push(item.id)
        })
        this.paymentObj = {
          fundsCmpCode: null,
          id: row.id,
          settleBankId: row.settleBankId || '',
          settleBankName: getBankName(row.settleBankId || ''),
          depositDetailIds: [...depositDetailIds],
          state: row.paymentStatus,
          money: this.depositListDataObj.totalMarginAmount
        }
      })
    },
    // 支付
    async paymentBondTable(row) {
      this.paymentObj = {
        fundsCmpCode: row.fundsCmpCode,
        depositDetailIds: [],
        id: row.id,
        settleBankId: row.settleBankId,
        settleBankName: getBankName(row.settleBankId || ''),
        state: row.paymentStatus,
        money: row.depositPayable
      }
      // 如果有了银行卡  就不要去选了
      if (this.paymentObj.settleBankId) {
        const confirm = await this.$confirm(`确认是否支付保证金${this.paymentObj.money}元？`, '提示', { type: 'warning' }).catch(err => err)
        if (confirm !== 'confirm') return
        this.getAccount(false)
        return
      }
      this.$refs.selectAccount.dialogTableVisible = true
    },
    // 保证金支付
    async getAccount(row) {
      if (!this.paymentObj.settleBankId && row) this.paymentObj.settleBankId = row.settleBankId
      // 如果是depositDetailIds大于0  说明调用支付接口2  如果等于0  就支付接口1
      if (this.paymentObj.depositDetailIds.length > 0 && !this.paymentObj.fundsCmpCode) {
        agentPurchaseContractDepositPayment({ ...this.paymentObj }, (res) => {
          this.$message.success('成功！')
          this.dialogTableVisible = false
          this.$refs.selectAccount.dialogTableVisible = false
          this.getdata(true)
          if (this.paymentObj.settleBankId === 'ABC')window.open(res.data, '_blank')
        }, err => {
          if (err.code === 9999) {
            this.$refs.selectAccount.dialogTableVisible = true
          }
        })
        return
      }
      if (this.paymentObj.settleBankId === 'ABC' && this.paymentObj.state === 6) {
        agentPurchaseContractGetDepositPaymentUrl({ ...this.paymentObj }, res => {
          this.getdata(true)
          window.open(res.data, '_blank')
          this.dialogTableVisible = false
          this.$refs.selectAccount.dialogTableVisible = false
        })
        return
      }

      // 保证金支付接口1
      agentPurchasePlanDepositPayment({
        custCmpCode: this.$getUserInfoParameter('cmpId'),
        firstFlag: 2,
        ...this.paymentObj
      }, (res) => {
        this.$message.success('成功！')
        this.getdata(true)
      }, err => {
        if (err.code === 9999) {
          this.$refs.selectAccount.dialogTableVisible = true
        }
      })
    },
    // 协议签署
    agreementSignTable(row) {
      this.agreementConfig.visible = true
      this.agreementConfig.data = row
      this.agreementConfig.signOrNot = true
    },
    // 签署确定
    confirmSign() {
      agentPurchaseContractGetEsignUrl({ contractId: this.agreementConfig.data.id, operFlat: 1 }, res => {
        this.agreementConfig.visible = false
        this.$message.success('成功！')
        window.open(res.data)
      })
    },
    // 查看协议
    viewAgreementTable(row) {
      this.agreementConfig.visible = true
      this.agreementConfig.data = row
      this.agreementConfig.signOrNot = false
    },
    // 提货申请
    aeliveryApplicationTable(row) {
      // 请求接口验证是否已经提货完成 或者是否还可以进行提货
      deliveryInfoCheckStartDeliveryApply({ id: row.id, deliveryStatus: row.contractStatus }, () => {
        this.$router.push({ path: '/purchaseContractManage/takeDeliveryApply', query: { id: row.id }})
      })
    }
  }
}
</script>

<style lang="scss">
.purchaseContractManage {
  .overdue-box{
    text-align: center;
    line-height: 22px;
    height: 22px;
    width: 54px;
    border-radius: 6px;
    font-size: 12px;
    background-color: $assistColor5;
    color: #fff;
  }
  .table-operation {
    margin-top: 18px;
    text-align: center;
  }

  .aggregateAmount {
    padding-bottom: 18px;
    color: $dominantColor;

    span {
      color: $pointClearColor;
      font-size: 17px;
      margin-right: 12px;
    }
  }
}
</style>
