import { postRequest, getRequest } from './index'
// 采购计划分页查询
export const agentPurchasePlanPage = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增采购计划
export const agentPurchasePlanAdd = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/add', data, res => (
    // console.log(res)
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 校验商品是否合法
export const agentPurchasePlanCheckGoodsLegal = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/checkGoodsLegal', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取件重id
export const goodSkuId = (data, successCallback, failureCallback) => {
  postRequest('/goods/skuId', data, res => (
    // console.log(res)
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 支付保证金
export const agentPurchasePlanDepositPayment = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/depositPayment', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购计划详情查询
export const agentPurchasePlanDetail = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 取消采购计划详情
export const agentPurchasePlanCancel = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/cancel', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 合作企业详情分页
export const agentSupplierList = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplier/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增采购计划的信息获取
export const agentPurchasePlanAgentPlanInfoForAdd = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/agentPlanInfoForAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增采购计划的信息获取
export const agentSupplierListSupplierCmpByFundsCmpCode = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplier/listSupplierCmpByFundsCmpCode', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 支付保证金之前先查询这一批要付多少钱的接口
export const agentPurchasePlanGetDepositAmountFirst = (id, successCallback, failureCallback) => {
  getRequest(`/agentPurchasePlan/getDepositAmountFirst/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取支付保证金支付的农行支付链接
export const agentPurchasePlanGetDepositPaymentUrl = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/getDepositPaymentUrl', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
